
/* width */
::-webkit-scrollbar {
    width: 6px;
    
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #000000; 
}
   
/* Handle */
::-webkit-scrollbar-thumb {
    background: #fff; 
    border-radius: 15px;
    
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #fff; 
}